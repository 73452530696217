// extracted by mini-css-extract-plugin
export var accomodationType = "style-module--accomodationType--0d6dc";
export var business = "style-module--business--98ef5";
export var cardListing = "style-module--cardListing--29cbc";
export var city = "style-module--city--80c15";
export var flex = "style-module--flex--31142";
export var image = "style-module--image--c0217";
export var linkWrapper = "style-module--linkWrapper--e8cf4";
export var onlineBooking = "style-module--onlineBooking--c0be5";
export var tagContainer = "style-module--tagContainer--f1981";
export var text = "style-module--text--b31fc";
export var title = "style-module--title--1d1a1";