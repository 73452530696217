import AlgoliaInstantSearch from '../AlgoliaInstantSearch'
import DateRangeInput from '../DateRangeInput'
import Modal from '../Modal'
import * as style from './style.module.scss'
import React from 'react'
import { RefinementList, ClearRefinements } from 'react-instantsearch-dom'
import { useDialogState, DialogDisclosure } from 'reakit/Dialog'

function FiltersModal() {
  const filtersModal = useDialogState({ baseId: 'filtersModal' })

  return (
    <>
      {/* Buttons */}
      <form>
        <div className={style.filterContainer}>
          <DialogDisclosure className={style.showFilters} {...filtersModal}>
            Show filters
          </DialogDisclosure>
        </div>
        {/* Modal */}
        <Modal {...filtersModal}>
          <div className={style.filtersMobile}>
            <div className={style.title}>
              <h2 className={style.h2}>Filters</h2>
              <button
                className={style.buttonClose}
                onClick={(event) => {
                  event.preventDefault()
                  filtersModal.hide()
                }}
              >
                Close
              </button>
            </div>
            <div className={style.inputSection}>
              <div className={style.searchSection}>
                <div className={style.searchInput}>
                  <div className={style.heading}>
                    <label className={style.label}>Location</label>
                    <ClearRefinements
                      translations={{ reset: 'Clear filters' }}
                      className={style.clearFilters}
                    />
                  </div>
                  <AlgoliaInstantSearch
                    name='location'
                    placeholder='Search by city or state'
                  />
                </div>
              </div>
            </div>
            <div className={style.inputSection}>
              <div className={style.searchSection}>
                <div className={style.inputRow}>
                  <div>
                    <label className={style.label} htmlFor='location'>
                      Drop-off and pick-up dates
                    </label>
                    <DateRangeInput attribute='unavailability' />
                  </div>
                </div>
              </div>
            </div>
            <div className={style.inputSection}>
              <div className={style.heading}>
                <h3>Accomodation</h3>
              </div>
              <RefinementList
                className={style.inputGroup}
                attribute='profileData.accomodationType'
              />
            </div>
            <div className={style.inputSection}>
              <h5>Dog(s)</h5>
              <div className={style.radios}>
                <div className={style.inputGroup}>
                  <input type='radio' id='one' name='dogs' value='one' />
                  <label htmlFor='one'>1</label>
                </div>
                <div className={style.inputGroup}>
                  <input type='radio' id='two' name='dogs' value='two' />
                  <label htmlFor='two'>2</label>
                </div>
                <div className={style.inputGroup}>
                  <input type='radio' id='three' name='dogs' value='three' />
                  <label htmlFor='three'>3</label>
                </div>
                <div className={style.inputGroup}>
                  <input type='radio' id='four' name='dogs' value='four' />
                  <label htmlFor='four'>4</label>
                </div>
              </div>
            </div>
            <div className={style.inputSection}>
              <h5>Dog size</h5>
              <RefinementList
                className={style.inputGroup}
                attribute='profileData.dogSize'
              />
            </div>
            <div className={style.inputSection}>
              <h5>Price range</h5>
              <RefinementList
                className={style.inputGroup}
                attribute='profileData.priceRange'
              />
            </div>
            <div className={style.inputSection}>
              <h5>Additional criteria</h5>
              <RefinementList
                className={style.inputGroup}
                attribute='profileData.additionalCriteria'
              />
            </div>
            <div className={style.fixedButtonContainer}>
              <button
                onClick={(event) => {
                  event.preventDefault()
                  filtersModal.hide()
                }}
              >
                View results
              </button>
            </div>
          </div>
        </Modal>
      </form>
    </>
  )
}

export default FiltersModal
