import * as style from './style.module.scss'
import { useLocation } from '@reach/router'
import * as React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'

function AlgoliaInstantSearch(props) {
  const location = useLocation()
  const category = props.category

  return (
    <form
      className={style.search}
      onSubmit={(event) => {
        event.preventDefault()
      }}
    >
      <div className={style.inputContainer}>
        <input
          aria-label='Search doggos.com'
          className={style.input}
          type='text'
          placeholder={props.placeholder}
          name='query'
          minLength='3'
          maxLength='160'
          defaultValue={props.currentRefinement}
          onChange={(event) => props.refine(event.currentTarget.value)}
        />
        {!location.pathname.startsWith('/search') ? (
          <button
            className={style.searchButton}
            type='submit'
            value={props.currentRefinement}
          >
            Search
          </button>
        ) : (
          ''
        )}
      </div>
    </form>
  )
}

export default connectSearchBox(AlgoliaInstantSearch)
