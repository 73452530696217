import PageSearch from '../components/PageSearch'
import { graphql } from 'gatsby'

export const query = graphql`
  query PageSearch {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default PageSearch
